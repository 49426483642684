import React, {useEffect} from 'react';
import { PersonalLoanSoftQuoteGem } from '@/components/PersonalLoanSoftQuote/pages-gem/PersonalLoanSoftQuoteGem';
import Metadata from '@/components/Metadata/Metadata';
import { SOFTQUOTE_NZ_METADATA } from '@/components/Metadata/Metadata.constants';
import { DatadogAppender } from '@/components/PersonalLoanSoftQuote/DatadogAppender';

const Index = props => {
  const hostname = props.location.hostname;
  let component = <PersonalLoanSoftQuoteGem props={props} />
  useEffect(() => {
    if (hostname === 'quote.gemfinance.co.nz') {
      component = <PersonalLoanSoftQuoteGem props = {props}/>;
    } else {
      if (typeof window !== `undefined`){
        window.location = '/personal-loans'
      } else {
        // Only required for yarn build
        return <div />
      }
    }
  }, []);
  return (
    <>
      <Metadata {...SOFTQUOTE_NZ_METADATA} />
      <DatadogAppender />
      {component}
    </>
  );
};
export default Index;
